











































import { Component, Vue, Prop } from 'vue-property-decorator';
import { setNameLang, makeToast } from '@/modules/budget-request/components/js/some-functions';
import { DateTimeFormatOptions } from 'vue-i18n';
import {getBipTitle, getCardTitle} from "@/modules/budget/bip/bip-types";
import i18nService from "@/services/i18n";
import AlterationI18nMixin from "@/modules/budget/alteration/mixins/alteration-i18n-mixin";

@Component({
    name: 'c-budg-agr-hist-modal'
})
export default class CBudgetAgreementHistModal extends AlterationI18nMixin {

    private showModal = false;

    private histArr: any [] = [];

    private agrObj: any = null;

    private histBase: any[] = [];

    private progress = 0;

    private get histLst() {
        const result: any[] = [];
        if (!this.agrObj) { return []; }
        const options: DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            // weekday: 'long',
            timeZone: 'Asia/Almaty'
        }
        for (const el of this.histBase) {
            const tmp = Object.assign({}, el);
            let locale = 'kk-KZ';
            switch (`${i18nService.locale}`) {
                case 'ru':
                    locale = 'ru-RU';
                    break;
                case 'en':
                    locale = 'en-EN';
                    break;
                default:
                    break;
            }
            tmp.updateDate = new Date(tmp.updateDate).toLocaleDateString(locale, options);
            result.push(setNameLang(this.$i18n.locale, tmp));
        }
        if (this.agrObj.userId) {
            const tmp = Object.assign(this.agrObj.status_lang ? this.agrObj.status_lang : {}, this.agrObj);
            tmp.updateDate = new Date(tmp.updateDate).toLocaleDateString('ru-RU', options);
            result.push(setNameLang(this.$i18n.locale, tmp));
        }
        return result;
    }

    private showEvent(agrObj: any) {

        this.histArr = [];
        if (agrObj === null || agrObj.id === null) { return; }
        this.agrObj = agrObj;

        if (this.agrObj.hasOwnProperty('mode_code') && this.agrObj.mode_code === 'bip') {
            this.loadBipHist(agrObj.id);
        } else {
            if (agrObj.hasOwnProperty('type') && agrObj.type === 6) {
                this.loadSpfHist(agrObj.id);
            } else {
                this.loadHist(agrObj.id);
            }
        }
        this.showModal = true;
    }

    private async loadBipHist(id: number) {
        this.histBase = [];
        this.progress = 25;
        try {
            const result: any = await fetch(`/api-py/get-bip-agree-hist/${id}`)
                .then((response) => response.json());
            this.progress = 65;
            await this.setUserNames(result);
            this.histBase = result;
            this.progress = 100;
        } catch (error) {
            makeToast(this, 'danger', this.setText('errors.err') + ' loadBipHist', (error as Error).toString());
            this.progress = 0;
            return;
        }
    }

    private async loadHist(id: number) {
        this.histBase = [];
        this.progress = 25;
        try {
            const result: any = await fetch(`/api-py/get-brfta-hist/${id}`)
                .then((response) => response.json());
            this.progress = 65;
            // result.push(this.agrObj);
            await this.setUserNames(result);
            this.histBase = result;
            this.progress = 100;
        } catch (error) {
            makeToast(this, 'danger', this.setText('errors.err') + ' /get-brfta-hist', (error as Error).toString());
            this.progress = 0;
            return;
        }
    }

    private async loadSpfHist(id: number) {
        this.histBase = [];
        this.progress = 25;
        try {
            const result: any = await fetch(`/api-py/get-spf-agree-hist/${id}`)
                .then((response) => response.json());

            this.progress = 65;
            await this.setUserNames(result);
            for (const row of result) {
                this.$set(row, 'name_ru', (row.status === 0 ? 'не соответствует' : 'соответствует'))
                this.$set(row, 'name_kk', (row.status === 0 ? 'сәйкес келмейді' : 'сәйкес келеді'))
                this.$set(row, 'name_en', (row.status === 0 ? 'does not match' : 'match'))
            }
            this.histBase = result;
            this.progress = 100;
        } catch (error) {
            makeToast(this, 'danger', this.setText('errors.err') + ' loadBipHist', (error as Error).toString());
            this.progress = 0;
            return;
        }
    }

    private async setUserNames(histBase: any[]) {
        const nameMap = new Map();
        for (const el of histBase) {
            if (el.userId) {
                nameMap.set(el.userId, '');
            }
        }
        if (this.agrObj.userId) { nameMap.set(this.agrObj.userId, ''); }
        for (const [key, val] of nameMap) {
            let result: any = null;
            try {
                const response: any = await fetch(`/api-py/get-realm-user/${encodeURI(key)}`);
                result = await response.json();
                if (result !== null) {
                    nameMap.set(key, (result.firstName ? result.firstName : '') + ' ' + (result.lastName ? result.lastName : ''));
                }
            } catch (error) {
                makeToast(this, 'danger', this.setText('errors.err') + ' get-realm-user ' + key, (error as Error).toString());
                return;
            }
        }

        this.agrObj.userName = nameMap.get(this.agrObj.userId);
        for (const el of histBase) {
            el.userName = nameMap.get(el.userId);
        }
    }

    getBipTitle(field: string) {
        return getBipTitle(this, field);
    }

    getCardTitle(sector: string, field: string) {
        return getCardTitle(this, sector, field);
    }

}
